import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(query)
  return (
    <Layout>
      <Seo title="Home" />
      <h1>The latest!</h1>
      <div className="divide-y-2 divide-tint-orange">
        {data.allStrapiPost.nodes.map((post, i) => {
          const { slug, title, body, posted_at } = post

          return <div key={slug} className="pt-6">
            <p className="text-xs">{new Date(posted_at).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</p>
            <h2><Link to={`/posts/${slug}`}>{title}</Link></h2>
            <div className="blogContent">
              <ReactMarkdown>{body}</ReactMarkdown>
            </div>
          </div>
        })}
      </div>
    </Layout>
  )
}

const query = graphql`
query HomeQuery {
  allStrapiPost(limit: 5, sort: {fields: posted_at, order: DESC}) {
    nodes {
      body
      posted_at
      title
      slug
    }
  }
}
`

export default IndexPage
